$app_min_width: 200px;

html {
	font-size: 9px;
	@include gt-md {
		font-size: 10px;
	}
	transition: font-size 0.2s;
}

body {
	background: #ffffff;
	color: $text1;
	min-width: $app_min_width;
	font-family: $font-text-pro;
	font-size: 1.4rem;
	letter-spacing: 0.026rem;
	height: 100vh;
	overflow: hidden;
}

#root,
.App {
	height: 100%;
	overflow: hidden;
}

.main {
	// min-height: calc(100% - 7.2rem); // Daniel: not necessary, otherwise will cut the scroll (will be useful when the footer is at the bottom)
	display: flex;
	flex-direction: column;
	flex: 1;
	// height: calc(100% - 7.2rem);

	& > section {
		flex: 1;
		height: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

/**
 * Basic styles for links
 */
a {
	color: $primary-color;

	@include on-event {
		color: $text-color;
		text-decoration: underline;
	}
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $text3;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $text2;
}

.pointer {
	cursor: pointer;
	user-select: none;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #cacaca;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #cacaca;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #cacaca;
}

.form-error {
	font-size: 1.2rem;
	color: #f35669;
}

.users-auto-complete-item {
	.ac-name {
		color: #000;
	}
	.ac-email {
		color: #a7aab7;
	}
}

.waves-bg {
	background-image: image('other/BACKGROUND WAVE2100.png');
	background-repeat: no-repeat;
	background-size: cover;
	//background-attachment: fixed;
	background-position: bottom right;
}

.scrollbar-container {
	&.hide-handles {
		.ps__rail-x,
		.ps__rail-y {
			display: none !important;
		}
	}
}

.app-date-picker {
	input {
		border: 1px solid #d7ddf1;
		border-radius: 0.3rem;
	}
}
.react-datepicker-popper {
	z-index: 999;
	padding-top: 12px !important;
	.react-datepicker {
		width: 282px;
	}

	.react-datepicker__header__dropdown {
		margin: 4px 0;
		display: flex;
		justify-content: center;
	}

	.react-datepicker__current-month {
		min-height: 3.4rem;
	}

	.react-datepicker__month {
		padding: 8px 16px !important;
	}

	.react-datepicker__month-dropdown-container,
	.react-datepicker__year-dropdown-container {
		margin: 0 2.8rem;
	}

	.react-datepicker__navigation {
		top: 0.7rem !important;
	}

	.react-datepicker__navigation-icon::before {
		border-color: #ffffff !important;
	}
	.react-datepicker__navigation--years-upcoming::before {
		content: '\25B2';
		display: block;
		background-image: image('icons/chevron-up.svg');
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		// top: 1rem;
	}
	.react-datepicker__navigation--years-previous,
	.react-datepicker__navigation--years-upcoming {
		position: initial !important;
		width: 2.5rem;
		height: 100%;
	}
	.react-datepicker__navigation--years-previous::after {
		content: '\25B2';
		display: block;
		background-image: image('icons/chevron-down.svg');
		background-repeat: no-repeat;
		background-position: center;
		height: 100%;
	}
}

.fade-enter {
	opacity: 0;
}
.fade-exit {
	opacity: 1;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
	transition: opacity 200ms;
}

.switch-toggle {
	vertical-align: middle;
	margin-bottom: 0.2rem;
}

.progress-bar + .progress-bar {
	margin-left: 0.3rem;
}

.red-tooltip.tooltip {
	.tooltip-inner {
		background-color: $danger;
	}
	.arrow::before {
		border-top-color: $danger;
	}
}

.orange-tooltip.tooltip {
	opacity: 1;
	.tooltip-inner {
		background-color: $highlight-color;
	}
	.arrow::before {
		border-top-color: $highlight-color;
	}
}

.clickable {
	cursor: pointer;
}
.react-datepicker-popper {
	z-index: 10;
}

.react-datepicker {
	border: unset;
	border-radius: 8px;
	font-size: 1.4rem !important;
	box-shadow: 2px 2px 1px $box-shadow-color-2;
	width: 28rem;
	* {
		outline: none !important;
	}
	&__header {
		padding: 8px 16px !important;
		background: $color1;
		color: #ffffff;
		.react-datepicker__day-name,
		.react-datepicker__current-month {
			color: #ffffff;
		}
		border: none;
	}

	&__month-read-view,
	&__year-read-view {
		visibility: visible !important;
		display: flex;
		gap: 1rem;
		align-items: center;
		&--down-arrow {
			order: 2;
			margin-bottom: 0.3rem;
			position: unset !important;
			height: 6px !important;
			width: 6px !important;
			top: 0.2rem !important;
			border-width: 2px 2px 0 0 !important;
			border-color: #ffffff !important;
			border-top-color: #ffffff !important;
			zoom: 1.1;
			right: -12px !important;
			position: unset !important;
			order: 2;
			margin-bottom: 2px;
			&:hover {
				border-top-color: #ffffff !important;
				border-color: #ffffff !important;
			}
		}
	}
	&__month-container {
		width: 100%;
	}
	&__month {
		border: 1px solid $gray2;
		border-top: none;
		margin: 0;
		padding: 0.8rem;
		border-bottom-left-radius: 0.3rem;
		border-bottom-right-radius: 0.3rem;
	}
	&__week,
	&__day-names {
		display: flex;
		justify-content: space-evenly;
	}
	&__triangle {
		left: 0 !important;
		right: 0 !important;
		margin: -8px auto !important;
		z-index: 9999;
		transform: unset !important;
		display: none !important;
		&::after {
			left: 12px;
			border-bottom-color: $color1 !important;
		}
	}
	&__day {
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50% !important;
		outline: 'none' !important;
		&--keyboard-selected {
			background: transparent !important;
		}
		&--selected {
			font-weight: bold;
			background: $color1 !important;
			// background: $color1-secondary !important;
			color: #ffffff !important;
		}
	}
	&__year-option,
	&__month-option {
		font-size: 1.4rem !important;
		padding: 4px 0;
		&:hover {
			&:not(.react-datepicker__year-option--selected_year):not(.react-datepicker__month-option--selected_month) {
				font-weight: bold;
				background: $background1;
				// background: none !important;
			}
		}
	}
	.react-datepicker__month-option--selected_month,
	.react-datepicker__year-option--selected_year {
		background: none !important;
		color: $color1;
	}
	&__year-dropdown,
	&__month-dropdown {
		max-height: 34rem;
		overflow: auto;
		user-select: none;
		background: #ffffff;
		color: $primary-color;
		top: 1rem !important;
	}
	&__month-dropdown {
		left: 33% !important;
	}
	&__year-option--selected_year,
	&__month-option--selected_month {
		background: $background1 !important;
		&:hover {
			background: $background1 !important;
		}
		color: $text1;
		font-weight: bold;
	}
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.order-4 {
	order: 4;
}

.order-5 {
	order: 5;
}

.order-6 {
	order: 6;
}

.order-7 {
	order: 7;
}

.order-8 {
	order: 8;
}

.order-9 {
	order: 9;
}

.order-10 {
	order: 10;
}

.MuiSkeleton-root {
	width: 100%;
}

.icon--action {
	height: 3.5rem;
	.page--equity & {
		height: inherit;
	}
}

.react-tel-input {
	width: 100%;
}

label {
	margin: 0;
}
