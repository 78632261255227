.link-button {
    &:hover {
        text-decoration: underline;
    }
    &.inverted {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    &.primary {
        color: $highlight-color;
    }
}
.btn.btn-thin {
    font-size: 1.4rem;
    line-height: 1;
    padding-left: 4rem;
    padding-right: 4rem;
}
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.text-center {
    text-align: center;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}
.text-color-black {
    color: #000;
}
.text-color-gray {
    color: #565656;
}
.text-color-error {
    color: $danger;
}

.font-12 {
    font-size: 1.2rem;
}
.font-14 {
    font-size: 1.4rem;
}
.font-15 {
    font-size: 1.5rem;
}
.font-16 {
    font-size: 1.6rem;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 500;
}

.text-medium {
    font-weight: 500;
}

.px-30 {
    @include padding(null 3rem);
}

.hidden {
    opacity: 0;
    filter: alpha(opacity=0);
    overflow: hidden;
    height: 0;
    width: 0;
}

.flex-1 {
    flex: 1 !important;
}

.flex {
    display: flex;
}

.align-self-end {
    align-self: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.scroll-y {
    height: 100%;
    overflow-y: auto;
}

.fixed {
    position: fixed !important;
}

.box-shadow-1 {
    box-shadow: $box-shadow-card1 !important;
}

.inner-loading {
    background: $background1;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-center {
    align-items: center !important;
}

.align-end {
    align-items: end !important;
}




.flex-column {
    display: flex;
    flex-direction: column;
}

.link {
    cursor: pointer;
    transition: all 0.3s;
    color: $color1;
    &:hover {
        text-decoration: underline;
    }
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.gap-3 {
    gap: 3rem;
}

.gap-4 {
    gap: 4rem;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}

.mr-auto {
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}

.mb--xs {
    margin-bottom: 0.8rem;
}

.mb--sm {
    margin-bottom: 1.6rem;
}

.mb--md {
    margin-bottom: 2.4rem;
}

.mb--lg {
    margin-bottom: 3.2rem;
}

.mb--xl {
    margin-bottom: 4rem;
}

.card-1 {
    box-shadow: $box-shadow-card1;
    background: #fff;
    position: relative;
    padding: 2.7rem 3.2rem;
    border-radius: 0.5rem;
    border: 1px solid #f1f1f1;
    flex: 1 1;
    width: 100%;
}

.pd-1 {
    padding: 10px;
}

.danger {
    color: $danger;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes progressLeftRight {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@keyframes shake {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
}

.text-ellipsis {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}

.disabled {
    opacity: 0.5;
    cursor: default;
}

.text-primary {
    color: $color1;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-2r {
    margin-top: 2rem;
}

.mb-2r {
    margin-bottom: 2rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 3rem;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.youtube-modal {
    background: transparent;
    div[data-modal="modal"] {
        box-shadow: none;
        background: transparent;
    }
}

.text-color-1{
    color: $color1;
}